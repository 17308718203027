<template>
  <m-widget-container class="d-flex align-center flex-shrink-0">
    <v-img
      :src="logo | mImage"
      max-height="90%"
      max-width="70vw"
      contain
      class="ml-1"
      position="left center"
    />
    <v-spacer />
    <m-clock-widget
      class="px-5"
      v-bind="mClockWidgetOptions"
      style="width: 20%;"
    />
  </m-widget-container>
</template>

<script>
import { MClockWidget, options as mClockWidgetOptions } from '../MClockWidget'
import { mWidgetMixin } from '../../../mixins'
export default {
  name: 'MHeader',
  components: {
    MClockWidget
  },
  mixins: [mWidgetMixin],
  props: {
    logo: {
      type: [String, Object],
      default: () => 'https://via.placeholder.com/128x128?text=LOGO'
    },
    mClockWidgetOptions: {
      type: Object,
      default: () => mClockWidgetOptions
    }
  }
}
</script>

<style lang="sass">
  .v-dialog.show-header.v-dialog--active.v-dialog--fullscreen
    height: calc(100vh - 128px)
    margin-top: 128px
    iframe.show-header
      height: calc(100vh - 128px)!important
</style>
